import React, { Component } from "react";
import { observer } from "mobx-react";
import { Web3Store } from "../stores/web3Store";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import '../styles/collectorCenter.less'
import { logger } from "typechain/dist/utils/logger";
import NavHeader from "../components/navHeader/index";
import BadgeModal from '../components/personalCenterModal/badgeModal'
import BannerModal from '../components/personalCenterModal/bannerModal'
import TreasureModal from '../components/personalCenterModal/treasureModal'
import { RootStore } from "../stores/rootStore";
import { Button, Modal, Input, Tooltip } from "antd";
import Banner from "../components/personalCenterBanners/index";
import Badge from '../components/badges/index'
import Treasure from '../components/treasure/index'
import NoData from '../components/noData/index'
import { QzukiItem } from "../components/qzukiItem";
import { getWalletString } from "../utils/commonUtils";
import autograph from '../images/qzuki/autograph.png'
import { getRoutePath, MythRouteId } from "../router-config";
import QzukiModal from "../components/galleryModal/qzukiModal";
import copy from "copy-to-clipboard";
import InfiniteScroll from 'react-infinite-scroll-component';
import { IAllBadges, IPersonalInfoBanners, IPersonalInfoDisplayBanner, IPersonalInfoDisplayQzukis, IPersonalResponse } from "../bean/ResponseBean";
import { TwitterShareButton } from "react-share";

const { Search } = Input;

interface IProps extends RouteComponentProps {
    web3Store: Web3Store
    rootStore: RootStore;
}

interface IStates {
    allQzukis: any[]
    Wallet: string | undefined
    ifOpenBadgeModal: boolean
    ifOpenBannerModal: boolean
    ifOpenTreasureModal: boolean
    galleryModel: boolean
    qzukiId: number
    treasures: any[]
    banners: IPersonalInfoBanners[]
    displayBanner: IPersonalInfoDisplayBanner
    ifSwitchBannerModal: boolean
    ifEditName: boolean
    name: string
    personalInfo: IPersonalResponse
    displayQzukisRange: number
    TooltipText: string
    ifPageModification: Boolean
    bannersfilterCriteria: string
    loading: boolean
    treasureLoading: boolean
    externalSelectionBadge: any
    bannerTotal: number
}

@observer
class CollectorPage extends Component<IProps, IStates> {
    private firstRequest: boolean = true;
    constructor(props: IProps, state: IStates) {
        super(props);

        let Wallet = undefined;
        try {
            Wallet = getWalletString('wallet').wallet;
        } catch (e) {
            console.log(e)
        }

        this.state = {
            allQzukis: [],
            Wallet: Wallet,
            ifPageModification: false,
            ifOpenBadgeModal: false,
            ifOpenBannerModal: false,
            ifOpenTreasureModal: false,
            galleryModel: false,
            qzukiId: -1,
            treasures: [{}, {}, {}, {}, {}],
            displayBanner: {
                balance: 0,
                id: 0,
                image: '',
                name: '',
                rarity: '',
                thumbnail: '',
                type: ''
            },
            banners: [],
            ifSwitchBannerModal: false,
            ifEditName: false,
            name: Wallet?.slice(0, 4) + '...' + Wallet?.slice(Wallet.length - 4, Wallet.length),
            personalInfo: {
                badges: [],
                badgesOwned: [],
                banners: [],
                displayBanner: {
                    balance: 0,
                    id: 0,
                    image: '',
                    name: '',
                    rarity: '',
                    thumbnail: '',
                    type: ''
                },
                displayQzukis: [],
                qzukis: [],
                userName: ''
            },
            displayQzukisRange: 1,
            TooltipText: 'COPY ADDRESS',
            bannersfilterCriteria: '',
            loading: false,
            treasureLoading: false,
            externalSelectionBadge: {},
            bannerTotal: 0
        }
    }

    componentDidMount(): void {
        this.fetchPersonalData()
    }

    private async fetchPersonalData() {
        const { Wallet } = this.state;
        const { web3Store } = this.props;
        const api = web3Store.mythServerApi;
        let handleTreasures: any[] = []
        let bannersNum = 0

        try {
            if (Wallet) {
                const personalResponse = await api.getPersonalInfo(Wallet)

                if (personalResponse.qzukis && personalResponse.displayQzukis.length === 0) {
                    handleTreasures = personalResponse.qzukis.slice(0, 5)
                } else {
                    personalResponse.displayQzukis.forEach(item => {
                        handleTreasures.push(item)
                    })
                }

                while (handleTreasures.length < 5) {
                    handleTreasures.push({})
                }

                personalResponse.badges.forEach(item => {
                    if (personalResponse.badgesOwned.indexOf(item.id) === -1) {
                        item.lightImage = ''
                    }
                })

                //删除第13个
                personalResponse.badges.splice(13, 1)
                const lightBadges = personalResponse.badges.filter(item => {
                    return item.lightImage !== ''
                })
                const darkBadges = personalResponse.badges.filter(item => {
                    return item.lightImage === ''
                })
                personalResponse.badges = [...lightBadges, ...darkBadges]

                personalResponse.banners.forEach(item => {
                    bannersNum = bannersNum + item.balance
                })

                this.setState({
                    displayBanner: personalResponse.displayBanner,
                    personalInfo: personalResponse,
                    banners: personalResponse.banners,
                    treasures: handleTreasures,
                    allQzukis: personalResponse.qzukis,
                    bannerTotal: bannersNum
                })
            }
        } catch (e) {
            logger.error(e);
        }
    }

    //检测你是否有权修改个人页面
    detectionModificationPage = () => {
        const { Wallet } = this.state;
        const { web3Store } = this.props;
        let ifEdit = false
        this.firstRequest = false;

        if (Wallet === web3Store.account && getWalletString('wallet').ifEdit) {
            ifEdit = true
        } else if (getWalletString('wallet').ifEdit) {
            const url = window.location.origin + getRoutePath(MythRouteId.CollectorPage) + "?wallet=" + Wallet;
            window.open(url, '_self');
        }

        this.setState({
            ifPageModification: ifEdit,
        })
    }

    openGalleryModel = (item: number) => {
        if (item) {
            this.setState({
                galleryModel: true,
                qzukiId: item
            })
        }
    }

    closeGalleryModel = (close: boolean) => {
        this.setState({
            galleryModel: close
        })
    }

    openCenterModal = (type: string,) => {
        const { ifOpenBadgeModal, ifOpenBannerModal, ifOpenTreasureModal, ifSwitchBannerModal, externalSelectionBadge } = this.state
        switch (type) {
            case 'badge':
                this.setState({
                    ifOpenBadgeModal: !ifOpenBadgeModal,
                    externalSelectionBadge: ifOpenBadgeModal ? '' : externalSelectionBadge
                })
                break
            case 'banner':
                this.setState({
                    ifOpenBannerModal: !ifOpenBannerModal
                })
                break
            case 'switch-banner':
                this.setState({
                    ifOpenBannerModal: !ifOpenBannerModal,
                    ifSwitchBannerModal: !ifSwitchBannerModal
                })
                break
            case 'treasure':
                this.setState({
                    ifOpenTreasureModal: !ifOpenTreasureModal
                })
                break
            default:
                break
        }
    }

    //修改展示的qzuki
    modifyDisplay = async (displayTreasure: any[]) => {
        const { web3Store } = this.props;
        const api = web3Store.mythServerApi
        const displayQzukisIds: any[] = []

        displayTreasure.forEach(item => {
            if (item.id) {
                displayQzukisIds.push(item.id)
            }
        })

        this.setState({ treasureLoading: true })
        try {
            const result = await api.updateDisplayQzukis(displayQzukisIds, web3Store.account)
            if (result) {
                this.setState({
                    ifOpenTreasureModal: false
                }, () => {
                    Modal.success({
                        content: 'Update display qzukis succeeded',
                        maskClosable: true,
                        className: 'personal-modal',
                        onOk: close => {
                            close();
                        },
                        afterClose: () => {
                            window.location.reload();
                        }
                    });
                })
            }
        } catch (e) {
            logger.error(e);

            Modal.error({
                content: 'Update display qzukis failed',
                maskClosable: true,
                className: 'personal-modal',
                onOk: close => {
                    close();
                }
            });
        } finally {
            this.setState({
                treasureLoading: false
            })
        }
    }

    //切换banner
    switchBanner = async (bannerToSwitch: IPersonalInfoBanners) => {
        const { ifSwitchBannerModal } = this.state
        const { web3Store } = this.props;
        const api = web3Store.mythServerApi;

        try {
            const result = await api.updateDisplayBanner(bannerToSwitch.id, web3Store.account);
            if (result) {
                this.setState({
                    displayBanner: bannerToSwitch,
                    ifOpenBannerModal: false,
                    ifSwitchBannerModal: !ifSwitchBannerModal
                }, () => {
                    Modal.success({
                        content: 'Update display banner succeeded',
                        maskClosable: true,
                        className: 'personal-modal',
                        onOk: close => {
                            close();
                        },
                        afterClose: () => {
                            window.location.reload();
                        }
                    });
                })
            }
        } catch (e) {
            logger.error(e);
            Modal.error({
                content: 'Update display banner failed',
                maskClosable: true,
                className: 'personal-modal',
                onOk: close => {
                    close();
                }
            });
        }
    }

    copy = () => {
        const { Wallet } = this.state
        if (Wallet) {
            copy(Wallet)
        }

        this.setState({
            TooltipText: 'COPIED'
        })
    }

    //修改名称
    confirmModificationName = async (value: any) => {
        const { name } = this.state
        const { web3Store } = this.props;
        const api = web3Store.mythServerApi;

        this.setState({ loading: true })
        try {
            if (value) {
                const result = await api.updateUserName(value, web3Store.account)
                if (result) {
                    this.setState({
                        ifEditName: false,
                        name: value ? value : name,
                    }, () => {
                        Modal.success({
                            content: 'Update user name succeeded',
                            maskClosable: true,
                            className: 'personal-modal',
                            onOk: close => {
                                close();
                            },
                            afterClose: () => {
                                window.location.reload();
                            }
                        });
                    })
                }
            } else {
                this.setState({
                    ifEditName: false,
                })
            }
        } catch (e) {
            logger.error(e);
            Modal.error({
                content: 'Update user name failed',
                maskClosable: true,
                className: 'personal-modal',
                onOk: close => {
                    close();
                }
            });
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    //筛选banner
    filterBanner = (label: string) => {
        const { banners, bannersfilterCriteria, personalInfo } = this.state
        const backUpbanners = personalInfo.banners
        let eligibleBanners = []

        switch (label) {
            case 'Rare':
                if (bannersfilterCriteria === 'Rare') {
                    eligibleBanners = backUpbanners
                } else {
                    eligibleBanners = backUpbanners.filter(item => { return item.rarity === 'Rare' })
                }
                break
            case 'Golden':
                if (bannersfilterCriteria === 'Golden') {
                    eligibleBanners = backUpbanners
                } else {
                    eligibleBanners = backUpbanners.filter(item => { return item.rarity === 'Golden' })
                }
                break
            case 'Mythical':
                if (bannersfilterCriteria === 'Mythical') {
                    eligibleBanners = backUpbanners
                } else {
                    eligibleBanners = backUpbanners.filter(item => { return item.rarity === 'Mythical' })
                }
                break
            default:
                eligibleBanners = banners
                break
        }

        this.setState({
            banners: eligibleBanners,
            bannersfilterCriteria: bannersfilterCriteria && bannersfilterCriteria === label ? '' : label
        })
    }

    selectBadge = (item: IAllBadges) => {
        this.setState({
            ifOpenBadgeModal: true,
            externalSelectionBadge: item
        })
    }

    render() {
        const { rootStore, web3Store } = this.props;
        const { bannerTotal, externalSelectionBadge, loading, treasureLoading, bannersfilterCriteria, banners, ifPageModification, TooltipText, displayQzukisRange, name, allQzukis, ifEditName, Wallet, displayBanner
            , personalInfo, ifOpenBadgeModal, ifOpenBannerModal
            , ifOpenTreasureModal, galleryModel, qzukiId, treasures, ifSwitchBannerModal } = this.state

        if (this.firstRequest && web3Store.account) {
            this.detectionModificationPage()
        }

        return <>
            <div className='Personal-Center-overflow'>
                <NavHeader web3Store={rootStore.web3Store} backgroundColor={'#f8e51a'} padding={'3vw'} position={true} />

                <div className="Personal-Center">
                    <div className="header-banner">
                        <img src={displayBanner.image} alt="" />

                        {
                            ifPageModification ?
                                <Button onClick={() => this.openCenterModal('switch-banner')}>Switch Banner</Button>
                                : ''
                        }
                    </div>

                    <div className="header-User">
                        <div className="header-User-Left">
                            <div className="User">
                                <div>
                                    {ifEditName ?
                                        <Search
                                            placeholder='Enter your name'
                                            allowClear
                                            autoFocus={true}
                                            loading={loading}
                                            size='middle'
                                            enterButton="confirm"
                                            onSearch={(value) => this.confirmModificationName(value)}
                                            style={{ width: '100%' }}
                                            onBlur={() => loading ? '' : this.setState({
                                                ifEditName: false
                                            })}
                                        /> :
                                        <h1>{personalInfo.userName ? personalInfo.userName : name}
                                            {ifPageModification ?
                                                <img src={autograph} alt="" draggable={false} onClick={() => this.setState({ ifEditName: true })} />
                                                : ""
                                            }
                                        </h1>
                                    }
                                    <Tooltip placement="right" title={TooltipText}>
                                        <div onClick={this.copy} onMouseLeave={() => setTimeout(() => this.setState({ TooltipText: 'COPY ADDRESS' }), 200)} className="wallet flex-row align-center">
                                            <p>{Wallet?.slice(0, 12)}...{Wallet?.slice(Wallet.length - 8, Wallet.length)}</p>
                                            <div className="fuzhi"></div>
                                        </div>
                                    </Tooltip>
                                </div>
                                <TwitterShareButton url={'https://qzuki.com/collector-page?wallet=' + Wallet} title="Check out my @QzukiOfficial Collector Page" className="iconfont icon-fenxiang01 twitter-share"><span className="iconfont icon-tuite">&nbsp; SHARE</span></TwitterShareButton>
                            </div>

                            <div className="treasure">
                                <h1>TREASURE {ifPageModification ? <img src={autograph} alt="" onClick={() => this.openCenterModal('treasure')} /> : ""}</h1>
                                <div className="display-treasure-nfts">
                                    {
                                        treasures.map((item, index) => {
                                            return <Treasure item={item} ifModal={false} openGalleryModel={this.openGalleryModel}></Treasure>
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="header-User-Right">
                            <div className="badge-title flex-row space-between">
                                <h1>ACHIEVEMENTS</h1>
                                <Button onClick={() => this.openCenterModal('badge')}>VIEW ALL</Button>
                            </div>

                            <div className={`badges ${personalInfo.badges.length !== 0 ? 'badges-gird' : ''}`}>
                                {
                                    personalInfo.badges.length !== 0 ?
                                        <>
                                            {
                                                personalInfo.badges.slice(0, 14).map((item: any, index: any) => {
                                                    return <Badge badge={item} noModalselectBadge={this.selectBadge}></Badge>
                                                })
                                            }
                                            <div className="more" onClick={() => this.openCenterModal('badge')}><p>+{personalInfo.badges.length - 14}</p></div>
                                        </>
                                        : <NoData noDatatext="No Badge NFT available"></NoData>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="banners">
                        <div className="banners-header">
                            <h1>BANNERS ({bannerTotal})</h1>
                            <div className="flex-row align-center space-between">
                                <div className="Cherish">
                                    <Button className={bannersfilterCriteria === 'Rare' ? 'bannersSelect' : ''} onClick={() => this.filterBanner('Rare')} >RARE</Button>
                                    <Button className={bannersfilterCriteria === 'Golden' ? 'bannersSelect' : ''} onClick={() => this.filterBanner('Golden')}>GOLDEN</Button>
                                    <Button className={bannersfilterCriteria === 'Mythical' ? 'bannersSelect' : ''} onClick={() => this.filterBanner('Mythical')}>MYTHICAL</Button>
                                </div>
                                <Button onClick={() => this.openCenterModal('banner')}>VIEW ALL</Button>
                            </div>
                        </div>
                        {
                            banners.length !== 0 ?
                                <div className="banner">
                                    {
                                        banners.slice(0, 6).map((item, index) => {
                                            return <Banner banner={item} index={index}></Banner>
                                        })
                                    }
                                </div>
                                : <NoData noDatatext="No Banner NFT available"></NoData>
                        }
                    </div>

                    <div className="QZUKI">
                        <h1>QZUKI ({allQzukis.length})</h1>
                        {
                            allQzukis.length !== 0 ?
                                <InfiniteScroll
                                    style={{ overflow: 'none' }}
                                    dataLength={displayQzukisRange * 100}
                                    next={() => this.setState({
                                        displayQzukisRange: displayQzukisRange + 1
                                    })}
                                    hasMore={displayQzukisRange * 100 < allQzukis.length}
                                    loader={<div></div>}
                                >
                                    <div className="allQzukis">
                                        {
                                            allQzukis.slice(0, displayQzukisRange * 100).map((item, index) => {
                                                return <QzukiItem key={"item-" + index} index={index} item={item} openGalleryModel={this.openGalleryModel} RouteId={MythRouteId.CollectorPage} ifPersonalQzukiItem={true} />
                                            })
                                        }
                                    </div>
                                </InfiniteScroll>
                                : <NoData noDatatext="No Qzuki NFT available"></NoData>}
                    </div>
                </div >
            </div >
            {
                ifOpenBadgeModal ?
                    <BadgeModal openBadgeModal={ifOpenBadgeModal} Badges={personalInfo.badges} closeModal={this.openCenterModal} web3Store={web3Store} externalSelectionBadge={externalSelectionBadge}></BadgeModal>
                    : ''
            }

            {
                ifOpenBannerModal ?
                    <BannerModal openBannerModal={ifOpenBannerModal} bannerTotal={bannerTotal} banners={personalInfo.banners} closeModal={this.openCenterModal} switchBanner={this.switchBanner} ifSwitchBannerModal={ifSwitchBannerModal}></BannerModal>
                    : ''
            }

            {
                ifOpenTreasureModal ?
                    <TreasureModal openTreasureModal={ifOpenTreasureModal} closeModal={this.openCenterModal} treasures={treasures} callback={this.modifyDisplay} web3Store={web3Store} loading={treasureLoading}></TreasureModal>
                    : ''
            }

            {
                galleryModel ? <QzukiModal web3Store={this.props.web3Store} openGalleryModel={galleryModel}
                    closeGalleryModel={this.closeGalleryModel} qzukiId={qzukiId} /> : ""
            }
        </>
    }
}

export default withRouter(CollectorPage);
