import React, { Component } from "react";
import { observer } from "mobx-react";
import { Web3Store } from "../stores/web3Store";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import '../styles/qzukiVerse.less'
import { logger } from "typechain/dist/utils/logger";
import NavHeader from "../components/navHeader/index";
// import CampModal from '../components/qzukiVerse/qzukiVerseModal/index'
import { RootStore } from "../stores/rootStore";
import { Button, message } from "antd";
import duel from '../images/qzuki-story/duel.jpg';
import smog from '../images/qzuki-story/smog.png';

interface IProps extends RouteComponentProps {
    web3Store: Web3Store
    rootStore: RootStore;
}

interface IStates {
    ifOpenShade: boolean
    roleId: number
    ifOpenCampModal: boolean
}

@observer
class QzukiVerse extends Component<IProps, IStates> {
    constructor(props: IProps, state: IStates) {
        super(props)

        this.state = {
            ifOpenShade: false,
            roleId: 0,
            ifOpenCampModal: false
        }
    }

    ifOpenShade = (id: number, clearId?: boolean) => {
        const { ifOpenShade } = this.state

        if (clearId) {
            this.setState({
                ifOpenShade: !ifOpenShade,
                roleId: 0
            })
        } else {
            this.setState({
                ifOpenShade: !ifOpenShade,
                roleId: id
            })
        }
    }

    openCampModal = () => {
        const { ifOpenCampModal } = this.state
        const { rootStore, web3Store } = this.props;

        if (!web3Store.account) {
            message.open({
                type: 'error',
                content: 'Please connect your wallet',
            });
        }

        this.setState({
            ifOpenCampModal: !ifOpenCampModal
        })
    }

    render() {
        const { rootStore, web3Store } = this.props;
        const { ifOpenShade, roleId, ifOpenCampModal } = this.state

        return <div className="qzuki-verse">
            <NavHeader web3Store={rootStore.web3Store} backgroundColor={'#f8e51a'} padding={'3vw'} position={true} />
            <div className="duel">
                <img src={duel} alt="" className="background-duel" />
                <img src={smog} alt="" className="smog" />

                <div className="role1-property" style={{ zIndex: `${ifOpenShade && roleId === 1 ? '19' : '1'}` }}>
                    <p className={ifOpenShade && roleId === 1 ? 'tag0' : 'tag-center'}>Ninja 忍者</p>
                    <b className={ifOpenShade && roleId === 1 ? 'tag tag1' : 'tag-center'}>stealthy</b>
                    <b className={ifOpenShade && roleId === 1 ? 'tag tag2' : 'tag-center'}>skilled</b>
                    <b className={ifOpenShade && roleId === 1 ? 'tag tag3' : 'tag-center'}>disciplined</b>
                    <b className={ifOpenShade && roleId === 1 ? 'tag tag4' : 'tag-center'}>covert</b>
                </div>
                <div className="role2-property" style={{ zIndex: `${ifOpenShade && roleId === 2 ? '19' : '2'}` }}>
                    <p className={ifOpenShade && roleId === 2 ? 'tag0' : 'tag-center'}>Necrolord 恶魔统领</p>
                    <b className={ifOpenShade && roleId === 2 ? 'tag tag1' : 'tag-center'}>demonic</b>
                    <b className={ifOpenShade && roleId === 2 ? 'tag tag2' : 'tag-center'}>powerful</b>
                    <b className={ifOpenShade && roleId === 2 ? 'tag tag3' : 'tag-center'}>corrupt</b>
                    <b className={ifOpenShade && roleId === 2 ? 'tag tag4' : 'tag-center'}>sinister</b>
                </div>
                <div className="role3-property" style={{ zIndex: `${ifOpenShade && roleId === 3 ? '19' : '3'}` }}>
                    <p className={ifOpenShade && roleId === 3 ? 'tag0' : 'tag-center'}>Zen Master 禅师</p>
                    <b className={ifOpenShade && roleId === 3 ? 'tag tag1' : 'tag-center'}>spiritual</b>
                    <b className={ifOpenShade && roleId === 3 ? 'tag tag2' : 'tag-center'}>disciplined</b>
                    <b className={ifOpenShade && roleId === 3 ? 'tag tag3' : 'tag-center'}>agile</b>
                    <b className={ifOpenShade && roleId === 3 ? 'tag tag4' : 'tag-center'}>wise</b>
                </div>
                <div className="role4-property" style={{ zIndex: `${ifOpenShade && roleId === 4 ? '19' : '4'}` }}>
                    <p className={ifOpenShade && roleId === 4 ? 'tag0' : 'tag-center'}>Weaponant 武器大师</p>
                    <b className={ifOpenShade && roleId === 4 ? 'tag tag1' : 'tag-center'}>skilled</b>
                    <b className={ifOpenShade && roleId === 4 ? 'tag tag2' : 'tag-center'}>versatile</b>
                    <b className={ifOpenShade && roleId === 4 ? 'tag tag3' : 'tag-center'}>precise</b>
                    <b className={ifOpenShade && roleId === 4 ? 'tag tag4' : 'tag-center'}>strategic</b>
                </div>
                <div className="role5-property" style={{ zIndex: `${ifOpenShade && roleId === 5 ? '19' : '1'}` }}>
                    <p className={ifOpenShade && roleId === 5 ? 'tag0' : 'tag-center'}>Reaper 死神</p>
                    <b className={ifOpenShade && roleId === 5 ? 'tag tag1' : 'tag-center'}>mysterious</b>
                    <b className={ifOpenShade && roleId === 5 ? 'tag tag2' : 'tag-center'}>powerful</b>
                    <b className={ifOpenShade && roleId === 5 ? 'tag tag3' : 'tag-center'}>relentless</b>
                    <b className={ifOpenShade && roleId === 5 ? 'tag tag4' : 'tag-center'}>ominous</b>
                </div>
                <div className="role6-property" style={{ zIndex: `${ifOpenShade && roleId === 6 ? '19' : '2'}` }}>
                    <p className={ifOpenShade && roleId === 6 ? 'tag0' : 'tag-center'}>Dark Swordsman 邪恶剑士</p>
                    <b className={ifOpenShade && roleId === 6 ? 'tag tag1' : 'tag-center'}>corrupt</b>
                    <b className={ifOpenShade && roleId === 6 ? 'tag tag2' : 'tag-center'}>powerful</b>
                    <b className={ifOpenShade && roleId === 6 ? 'tag tag3' : 'tag-center'}>ruthless</b>
                    <b className={ifOpenShade && roleId === 6 ? 'tag tag4' : 'tag-center'}>dangerous</b>
                </div>
                <div className="role7-property" style={{ zIndex: `${ifOpenShade && roleId === 7 ? '11' : '3'}` }}>
                    <p className={ifOpenShade && roleId === 7 ? 'tag0' : 'tag-center'}>Elementalist 元素大师</p>
                    <b className={ifOpenShade && roleId === 7 ? 'tag tag1' : 'tag-center'}>mystical</b>
                    <b className={ifOpenShade && roleId === 7 ? 'tag tag2' : 'tag-center'}>spellcasting</b>
                    <b className={ifOpenShade && roleId === 7 ? 'tag tag3' : 'tag-center'}>enchanting</b>
                    <b className={ifOpenShade && roleId === 7 ? 'tag tag4' : 'tag-center'}>arcane</b>
                </div>
                <div className="role8-property" style={{ zIndex: `${ifOpenShade && roleId === 8 ? '11' : '4'}` }}>
                    <p className={ifOpenShade && roleId === 8 ? 'tag0' : 'tag-center'}>Sealmancer 封印师</p>
                    <b className={ifOpenShade && roleId === 8 ? 'tag tag1' : 'tag-center'}>mystical</b>
                    <b className={ifOpenShade && roleId === 8 ? 'tag tag2' : 'tag-center'}>knowledgeable</b>
                    <b className={ifOpenShade && roleId === 8 ? 'tag tag3' : 'tag-center'}>strategic</b>
                    <b className={ifOpenShade && roleId === 8 ? 'tag tag4' : 'tag-center'}>manipulative</b>
                </div>

                <div className="role1-box" style={{ zIndex: `${ifOpenShade && roleId === 1 ? '20' : '11'}` }} onMouseOver={() => this.ifOpenShade(1)} onMouseOut={() => this.ifOpenShade(1, true)}></div>
                <div className="role2-box" style={{ zIndex: `${ifOpenShade && roleId === 2 ? '20' : '12'}` }} onMouseOver={() => this.ifOpenShade(2)} onMouseOut={() => this.ifOpenShade(2, true)}></div>
                <div className="role3-box" style={{ zIndex: `${ifOpenShade && roleId === 3 ? '20' : '13'}` }} onMouseOver={() => this.ifOpenShade(3)} onMouseOut={() => this.ifOpenShade(3, true)}></div>
                <div className="role4-box" style={{ zIndex: `${ifOpenShade && roleId === 4 ? '20' : '14'}` }} onMouseOver={() => this.ifOpenShade(4)} onMouseOut={() => this.ifOpenShade(4, true)}></div>
                <div className="role5-box" style={{ zIndex: `${ifOpenShade && roleId === 5 ? '20' : '15'}` }} onMouseOver={() => this.ifOpenShade(5)} onMouseOut={() => this.ifOpenShade(5, true)}></div>
                <div className="role6-box" style={{ zIndex: `${ifOpenShade && roleId === 6 ? '20' : '16'}` }} onMouseOver={() => this.ifOpenShade(6)} onMouseOut={() => this.ifOpenShade(6, true)}></div>
                <div className="role7-box" style={{ zIndex: `${ifOpenShade && roleId === 7 ? '20' : '17'}` }} onMouseOver={() => this.ifOpenShade(7)} onMouseOut={() => this.ifOpenShade(7, true)}></div>
                <div className="role8-box" style={{ zIndex: `${ifOpenShade && roleId === 8 ? '20' : '18'}` }} onMouseOver={() => this.ifOpenShade(8)} onMouseOut={() => this.ifOpenShade(8, true)}></div>

                {
                    web3Store.account ?
                        <Link to={'/hero'}>
                            <Button className="join-btn" size="large" onClick={this.openCampModal}>JOIN THE BATTLE</Button>
                        </Link>
                        :
                        <Button className="join-btn" size="large" onClick={this.openCampModal}>JOIN THE BATTLE</Button>
                }


                <div className={ifOpenShade ? "shade ifShowShade" : 'shade '}></div>
            </div>

            {/* {
                ifOpenCampModal ?
                    <CampModal openCampModal={ifOpenCampModal} ifOpenCampModal={this.openCampModal} web3Store={web3Store}></CampModal> : ''
            } */}
        </div >
    }
}

export default withRouter(QzukiVerse);
