import moment from "moment";

export const delayAsync = (ms: number) => new Promise<void>(resolve => setTimeout(() => resolve(), ms));

export const formatTime = (time: string): number => {
    return moment(time, "yyyy-MM-DD HH:mm:ss").valueOf() / 1000;
};

export const getCurrentUtc = (): number => {
    let d1 = new Date();
    let d2 = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds());
    return d2.getTime();
};

export const finneyToEth = (finney: number) => {
    return finney / 1000;
};


export const handleNFTName = (collectionName: string, tokenId: string) => {
    return `${collectionName} #${tokenId}`;
};

export const toOpensea = (account: string) => {
    window.open('https://testnets.opensea.io/' + account)
}

export function getQueryString(param: string) {
    const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)');
    const r = window.location.search.substring(1).match(reg) || window.location.hash.substring((window.location.hash.search("/?/")) + 1).match(reg);
    if (r) {
        return decodeURIComponent(r[2]);
    }
    return "";
}

export function getWalletString(param: string) {
    const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)');
    let ifEdit = false

    const r = window.location.search.substring(1).match(reg) || window.location.hash.substring((window.location.hash.search("/?/")) + 1).match(reg);
    if (window.location.pathname.indexOf('edit') !== -1) {
        ifEdit = true
    }
    if (r) {
        return {
            wallet: decodeURIComponent(r[2]),
            ifEdit: ifEdit
        };
    }
    return {
        wallet: '',
        ifEdit: ifEdit
    };
}

export const calculateProgress = (remain: number, total: number) => {
    return (total - remain) / total * 100;
};


export const goToTransaction = (name: string, id: string) => {
    switch (name) {
        case 'opensea':
            return ("https://opensea.io/assets/ethereum/0xcde8f5008c313820b558addfcd8628e20cc1c2fe/" + id)
        case 'blur':
            return 'https://blur.io/collection/qzukinft'
        case 'looksrare':
            return ("https://looksrare.org/collections/0xCDE8f5008c313820B558AdDFcD8628e20cc1c2fe/" + id)
        case 'element':
            return ("https://element.market/assets/0xcde8f5008c313820b558addfcd8628e20cc1c2fe/" + id)
        case 'x2y2':
            return ("https://x2y2.io/eth/0xCDE8f5008c313820B558AdDFcD8628e20cc1c2fe/" + id)
    }
}