import {appEnv} from "../env";

export function getMarketPlace(name: string) {
    const markets = appEnv.marketPlace;
    for (let i = 0; i < markets.length; i++) {
        if (name === markets[i].name) {
            return markets[i].collectionUrl;
        }
    }

    return "";
}

export const isEmpty = (value: any) => {
    switch (typeof value) {
        case 'undefined':
            return true;
        case 'string':
            if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length === 0) return true;
            break;
        case 'boolean':
            if (!value) return true;
            break;
        case 'number':
            if (0 === value || isNaN(value)) return true;
            break;
        case 'object':
            if (null === value || value.length === 0) return true;
            for (var i in value) {
                return false;
            }
            return true;
    }
    return false;
}


export enum MythServerResultCode {
    SUCCESS = 10000
}

export const TOTAL_SUPPLY = 10000;
export const AZUKI_SUPPLY = 4000;
export const BEANZ_SUPPLY = 2000;
export const TEAM_HOLD_NUM = 500;
export const PUBLIC_MINT_SINGLE_PRICE = 0.01;//0.01ETH

export enum MintStage {
    NotStarted, WhitelistMint, HolderMint, PublicMint
}

export enum WhiteListMintStateEnum {
    MintAble = 'MintAble',
    NotWhitelist = "NotWhitelist",
    Minted = 'Minted'
}

export enum MarketPlace {
    Opensea = "Opensea", X2Y2 = "X2Y2", Element = "Element", Lookrare = "Lookrare"
}

export function getBackgroundColor(item: any) {
    let backgroundColor = '#46436e';
    switch (item.attributes.Background) {
        case "Off White D":
            backgroundColor = '#a19e99'
            break;
        case "Off White C":
            backgroundColor = '#bbbeb7'
            break;
        case "Off White A":
            backgroundColor = '#eff3f2'
            break;

        case "Off White B":
            backgroundColor = '#dfdfdd'
            break;

        case "Red":
            backgroundColor = '#c62f42'
            break;

        case "Dark Blue":
            backgroundColor = '#2f4773'
            break;

        case "Cool Gray":
            backgroundColor = '#403e53'
            break;

        case "Dark Purplr":
            backgroundColor = '#46436e'
            break;
        default:
            backgroundColor = '#46436e';
            break
    }

    return backgroundColor;
}

export function addBackgroundColor(item: any) {
    item.backgroundColor = getBackgroundColor(item);
}

export function checkIconDark(backgroundColor: string) {
    return backgroundColor === '#eff3f2' || backgroundColor === '#dfdfdd' ||
        backgroundColor === '#bbbeb7' || backgroundColor === '#a19e99'
}

export function convertAttributes(item: any) {
    const attributes: any[] = [];
    Object.entries(item.attributes).forEach(item => {
        const attribute = {
            key: item[0],
            value: item[1]
        };
        attributes.push(attribute)
    });
    return attributes;
}

export function parseTokenId(name: string | undefined) {
    if (name) {
        return name?.split(' ')[1].split('#')[1];
    }

    return '';
}
