import React, { Component } from "react";
import { observer } from "mobx-react";
import { Web3Store } from "../stores/web3Store";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import '../styles/hero.less'
import { logger } from "typechain/dist/utils/logger";
import NavHeader from "../components/navHeader/index";
import heroData from '../data/heroData.json'
import { RootStore } from "../stores/rootStore";
import { Button, Input, Tag, message, Select, Tooltip } from "antd";
import { LeftCircleOutlined, RightCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { IAllMyQzuki } from '../bean/ResponseBean'
import duel from '../images/qzuki-story/duel.jpg';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/swiper.less";
import '../styles/iconfont/font-eth/iconfont.css'

const { TextArea } = Input;

interface IProps extends RouteComponentProps {
    web3Store: Web3Store
    rootStore: RootStore;
}

interface IStates {
    name: string
    selectedPersonalitys: Map<string, string>
    model: number
    ifShowTextArea: boolean
    swiperSpaceBetween: number
    occupation: {
        id: number,
        name: string,
        rarity: string,
        trait: string[]
        introductionInChinese: string,
        englishIntroduction: string
    }
    story: string
    qzukis: IAllMyQzuki[]
    writeStoriesQzuki: {
        id: number,
        img: string
    }
    meetTheConditions: Map<string, string>
    optionalOccupation: Map<number, number>
    loading: {
        ai: boolean
        submit: boolean
    }
    aiLanguage: string
    ifUsedAI: boolean
    aiNum: number
}

@observer
class Hero extends Component<IProps, IStates> {
    public first = true
    constructor(props: IProps, state: IStates) {
        super(props)

        this.state = {
            name: '',
            selectedPersonalitys: new Map(),
            model: 1,
            ifShowTextArea: false,
            swiperSpaceBetween: 15,
            occupation: {
                id: -1,
                name: '',
                rarity: '',
                trait: [],
                introductionInChinese: '',
                englishIntroduction: ''
            },
            story: '',
            qzukis: [],
            writeStoriesQzuki: {
                id: -1,
                img: ''
            },
            meetTheConditions: new Map(),
            optionalOccupation: new Map(),
            loading: {
                ai: false,
                submit: false
            },
            aiLanguage: 'CN',
            ifUsedAI: false,
            aiNum: 0
        }
    }

    componentDidMount(): void {
        this.changeScrollTopShow()
        window.addEventListener('resize', this.changeScrollTopShow);
    }

    changeScrollTopShow = () => {
        if (window.innerWidth <= 550) {
            this.setState({
                swiperSpaceBetween: 10
            });
        } else if (window.innerWidth <= 400) {
            this.setState({
                swiperSpaceBetween: 5
            });
        }
    };

    //卸载事件监听
    componentWillUnmount() {
        window.removeEventListener("resize", this.changeScrollTopShow);
    }

    //获取该钱包里的qzukis
    private async fetchHeroData() {
        const { web3Store } = this.props;
        const api = web3Store.mythServerApi;
        try {
            if (web3Store.account) {
                const qzukis = await api.getAllMyQzukis(web3Store.account)

                this.setState({
                    qzukis: qzukis,
                })
            }
        } catch (e) {
            logger.error(e);
        }
    }

    enterName = (e: any) => {
        this.setState({
            name: e.target.value
        })
    }

    //选择性格
    chooseCharacter = (character: string) => {
        const { selectedPersonalitys } = this.state

        if (selectedPersonalitys.has(character)) {
            selectedPersonalitys.delete(character)
        } else if (selectedPersonalitys.size <= 2) {
            selectedPersonalitys.set(character, character)
        }

        this.setState({
            selectedPersonalitys: selectedPersonalitys
        })
    }

    selectMode = (id: number) => {
        if (this.state.model !== id) {
            this.setState({
                model: id,
                ifShowTextArea: id === 1 ? false : true,
                ifUsedAI: false
            })
        }
    }

    //选择要编写的qzuki，并获取之前这张qzuki是否有过编写记录，同时获取该qzuki使用ai次数
    selectQzuki = async (qzuki: IAllMyQzuki) => {
        const { writeStoriesQzuki, meetTheConditions, optionalOccupation, selectedPersonalitys } = this.state
        const { web3Store } = this.props;
        const api = web3Store.mythServerApi;
        let aiNum = 0

        try {
            this.clearQzukiData()

            if (writeStoriesQzuki.id !== qzuki.id && web3Store.account) {
                const storyData = await api.getUserLastQzukiHero(qzuki.id, web3Store.account)
                aiNum = await api.getSubmitCounts(qzuki.id, web3Store.account)
                const hero = await api.getMateOccupations(qzuki.id, web3Store.account)

                if (storyData.hero !== null) {
                    const qzukiOccupation = heroData.hero.filter(item => item.id === storyData.hero[0].occupationId)

                    this.setState({
                        name: storyData.hero[0].heroName,
                        story: storyData.hero[0].story,
                        occupation: {
                            id: qzukiOccupation[0].id,
                            name: qzukiOccupation[0].name,
                            rarity: qzukiOccupation[0].rarity,
                            trait: qzukiOccupation[0].trait,
                            introductionInChinese: qzukiOccupation[0].introductionInChinese,
                            englishIntroduction: qzukiOccupation[0].englishIntroduction
                        }
                    })

                    Object.keys(JSON.parse(storyData.hero[0].heroPersonality)).forEach((item: string) => {
                        selectedPersonalitys.set(item, item)
                    })
                }

                hero.occupationIds.forEach(item => {
                    optionalOccupation.set(item, item)
                })
                hero.traitSet.forEach(item => {
                    meetTheConditions.set(item.toUpperCase(), item.toUpperCase())
                })
            }
        } catch (e) {
            logger.error(e);
        } finally {
            this.setState({
                writeStoriesQzuki: {
                    id: qzuki.id,
                    img: qzuki.thumbnail,
                },
                aiNum: 5 - aiNum,
                meetTheConditions: meetTheConditions,
                optionalOccupation: optionalOccupation,
                selectedPersonalitys: selectedPersonalitys
            })
        }
    }

    //每次选择qzuki编写故事时，清空上一个qzuki的编写数据
    clearQzukiData = () => {
        const { writeStoriesQzuki, selectedPersonalitys, meetTheConditions, optionalOccupation } = this.state

        if (writeStoriesQzuki.id !== -1) {
            this.setState({
                name: '',
                story: '',
                occupation: {
                    id: -1,
                    name: '',
                    rarity: '',
                    trait: [],
                    introductionInChinese: '',
                    englishIntroduction: ''
                },
                ifShowTextArea: false,
                model: 1
            })
            selectedPersonalitys.clear()
            meetTheConditions.clear()
            optionalOccupation.clear()
        }
    }

    //提交写好的数据
    sumbitStory = async () => {
        const { writeStoriesQzuki, story, name, occupation, selectedPersonalitys } = this.state
        const { web3Store } = this.props;
        const api = web3Store.mythServerApi;
        const personalitys: string[] = []

        this.setState({
            loading: {
                ai: false,
                submit: true
            }
        })
        if (writeStoriesQzuki.id === -1) {
            message.open({
                type: 'error',
                content: 'Please select qzuki to write the story',
            });
        } else if (name.length === 0) {
            message.open({
                type: 'error',
                content: 'Please write your name',
            });
        } else if (occupation.id === -1) {
            message.open({
                type: 'error',
                content: 'Please choose a profession',
            });
        } else if (selectedPersonalitys.size === 0) {
            message.open({
                type: 'error',
                content: 'Please select a personality',
            });
        } else if (story.length === 0) {
            message.open({
                type: 'error',
                content: 'Please write a story',
            });
        } else {
            selectedPersonalitys.forEach((key, value) => {
                personalitys.push(value)
            })
            try {
                const submitStory = await api.submitHeroStory(writeStoriesQzuki.id, web3Store.account, story, name, occupation.id, personalitys)

                if (submitStory) {
                    message.open({
                        type: 'success',
                        content: 'Successfully submitted story',
                    });
                }
            } catch (e) {
                logger.error(e);
            }
        }
        this.setState({
            loading: {
                ai: false,
                submit: false
            }
        })
    }

    //调用chatGPT生成故事
    generateStory = async () => {
        const { name, writeStoriesQzuki, occupation, selectedPersonalitys, aiLanguage, aiNum } = this.state
        const { web3Store } = this.props;
        const api = web3Store.mythServerApi;
        const personalitys: string[] = []
        let language: string = ''

        try {
            this.setState({
                loading: {
                    ai: true,
                    submit: false
                }
            })

            if (aiLanguage === 'CN') {
                language = '中文'
            } else {
                language = '英文'
            }

            selectedPersonalitys.forEach((key, value) => {
                personalitys.push(value)
            })

            if (web3Store.account && writeStoriesQzuki.id !== -1 && occupation.id !== -1 && aiNum > 0 && name.length !== 0 && selectedPersonalitys.size !== 0) {
                const aiStory = await api.chatGPT(web3Store.account, writeStoriesQzuki.id, name, occupation.name, personalitys, language, occupation.introductionInChinese)
                if (aiStory.choices[0].message.content) {
                    this.setState({
                        story: aiStory.choices[0].message.content,
                        ifUsedAI: true,
                        aiNum: aiNum - 1
                    })
                }
            } else if (writeStoriesQzuki.id === -1) {
                message.open({
                    type: 'error',
                    content: 'Please select qzuki',
                });
            } else if (name.length === 0) {
                message.open({
                    type: 'error',
                    content: 'Please enter a name in the input box above',
                });
            } else if (occupation.id === -1) {
                message.open({
                    type: 'error',
                    content: 'Please choose a profession',
                });
            } else if (selectedPersonalitys.size === 0) {
                message.open({
                    type: 'error',
                    content: 'Please select at least one personality above',
                });
            } else if (aiNum <= 0) {
                message.open({
                    type: 'error',
                    content: 'AI usage has been exhausted',
                });
            }
        } catch (e) {
            logger.error(e);
        } finally {
            this.setState({
                loading: {
                    ai: false,
                    submit: false
                },
            })
        }
    }


    render() {
        const { rootStore, web3Store } = this.props;
        const { name, story, aiLanguage, selectedPersonalitys, model, ifShowTextArea, swiperSpaceBetween, occupation, qzukis, writeStoriesQzuki, meetTheConditions, optionalOccupation, loading, ifUsedAI, aiNum } = this.state

        const personalitys = ['Helpful', 'Responsible', 'Understanding', 'Tenacious', 'Optimistic', 'Confident', 'Brave',
            'Humorous', 'Cautious', 'Impulsive', 'Haughty', 'Cold', 'Aggressive', 'Picky']

        if (this.first) {
            setTimeout(() => {
                this.fetchHeroData()
                this.first = false
            }, 1200)
        }

        return <div className="hero">
            <NavHeader web3Store={rootStore.web3Store} backgroundColor={'#f8e51a'} padding={'3vw'} position={true} />
            <div className="hero-story-generation">
                <img src={duel} alt="" className="battlefield" />
                <div className="shade"></div>

                <div className="hero-content">
                    <div className="hero-select">
                        <h1>Select Qzuki to join the battle</h1>
                        <div className="select">
                            <div className="selected-graph">
                                <img src={qzukis.length !== 0 ? writeStoriesQzuki.img : ''} alt="" />
                            </div>
                            <div className="selectable-editing-area">
                                <p>Qzuki #{qzukis.length !== 0 && writeStoriesQzuki.id !== -1 ? writeStoriesQzuki.id : ''}</p>
                                <div style={{ position: 'relative' }}>
                                    <LeftCircleOutlined className="prev" />
                                    <RightCircleOutlined className="next" />
                                    <Swiper
                                        className="Carousel"
                                        modules={[Navigation]}
                                        slidesPerView='auto'
                                        spaceBetween={swiperSpaceBetween}
                                        navigation={{ prevEl: '.prev', nextEl: '.next' }}
                                    >
                                        {
                                            qzukis.map((item, index) => {
                                                return <SwiperSlide key={index}>
                                                    <div className={writeStoriesQzuki.id === item.id ? 'selectable-image qzuki-status' : "selectable-image"} onClick={() => this.selectQzuki(item)}>
                                                        <img src={item.thumbnail} alt="" className="Mobile-swiper-img" />
                                                    </div>
                                                </SwiperSlide>
                                            })
                                        }
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="name-input">
                        <p>Tell us your name</p>
                        <Input size='large' value={name} onChange={(e) => this.enterName(e)} placeholder="PLEASE ENTER YOUR NAME" suffix={name ? <div className="iconfont icon-xingzhuangjiehe"></div> : <CheckCircleOutlined style={{ color: 'white' }} />}></Input>
                    </div>

                    <div className="choosing-a-profession">
                        <div className="career-name">
                            <p>{occupation.name ? occupation.name : '....'}</p>
                            {
                                occupation.rarity === 'Rare' ?
                                    <Tag color="#3078ff">Rare</Tag>
                                    : occupation.rarity === 'Epic' ?
                                        <Tag color="#6c48f0">Epic</Tag> :
                                        occupation.rarity === 'Legendary' ?
                                            <Tag color="#fea143">Legendary</Tag> :
                                            occupation.rarity === 'Myth' ?
                                                <Tag color="#c62f42">Myth</Tag>
                                                : ''
                            }
                        </div>
                        <div className="activation-conditions">
                            <p>Trait:</p>
                            <div className="trait">
                                <Swiper
                                    className="Carousel"
                                    slidesPerView='auto'
                                    spaceBetween={10}
                                >
                                    {
                                        occupation.trait.length !== 0 ?
                                            occupation.trait.map((item, index) => {
                                                return <SwiperSlide><Tag key={index} color={meetTheConditions.has(item.toUpperCase()) || item.toUpperCase() === "ALL" ? '#f8e51a' : ''} style={{ color: `${meetTheConditions.has(item.toUpperCase()) || item.toUpperCase() === "ALL" ? 'black' : ''}` }}>{item.toUpperCase()}</Tag>
                                                </SwiperSlide>
                                            }) :
                                            ''
                                    }
                                </Swiper>
                                {/* <Tag color="#978887">Fire</Tag>
                                <Tag color="#978887">Fire</Tag> */}
                            </div>
                        </div>
                        <div className="introduce">
                            <p>{occupation.englishIntroduction}</p>
                        </div>
                        <div className="optional-profession">
                            <LeftCircleOutlined className="occupation-prev" />
                            <RightCircleOutlined className="occupation-next" />
                            <Swiper
                                className="Carousel"
                                modules={[Navigation]}
                                slidesPerView='auto'
                                spaceBetween={swiperSpaceBetween}
                                navigation={{ prevEl: '.occupation-prev', nextEl: '.occupation-next' }}
                            >
                                {
                                    heroData.hero.map((item, key) => {
                                        return <SwiperSlide>
                                            <div className={occupation.id === item.id ? 'selectable-image occupational-status' : "selectable-image"} onClick={() => optionalOccupation.has(item.id) ? this.setState({ occupation: { id: item.id, name: item.name, rarity: item.rarity, trait: item.trait, introductionInChinese: item.introductionInChinese, englishIntroduction: item.englishIntroduction } }) : ''}>
                                                <img src={item.img} alt="" className={optionalOccupation.has(item.id) ? 'career-pictures' : 'career-pictures picture-grays-out'} style={{
                                                    transform: `${item.id === 7 ? 'scale(1.3)' :
                                                        item.id === 8 ? 'scale(1.3)' :
                                                            item.id === 10 ? 'translateX(20%) scale(1.3)' :
                                                                item.id === 11 ? 'translateX(-15%) scale(1.3)' :
                                                                    item.id === 12 ? 'translateX(-15%) scale(1.3)' :
                                                                        item.id === 13 ? 'translateX(-15%) scale(1.2)' :
                                                                            item.id === 15 ? 'translateX(-15%) scale(1.2)' :
                                                                                item.id === 16 ? 'scale(1.3)' :
                                                                                    item.id === 17 ? 'translateX(5%)' : ''}`
                                                }} key={key} />

                                                <div className="career-shade" style={{
                                                    background: `${optionalOccupation.has(item.id) ? item.rarity === 'Rare' ? 'linear-gradient(to top, #2f76fa, #2f76fa00)'
                                                        : item.rarity === 'Epic' ? 'linear-gradient(to top, #6a46ea, #6a46ea00)'
                                                            : item.rarity === 'Legendary' ? 'linear-gradient(to top, #fc9f42, #fc9f4200)'
                                                                : item.rarity === 'Myth' ? 'linear-gradient(to top, #c12d40, #c12d4100)' : '' : 'linear-gradient(to top, #878787, #87878700)'}`
                                                }}></div>
                                            </div>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>

                    <div className="heroic-character">
                        <p>Hero's personality (select up to 3)</p>
                        <div className="characters">
                            {
                                personalitys.map((i, k) => {
                                    return <div className={selectedPersonalitys.has(i) ? 'character selected-status' : "character"} onClick={() => this.chooseCharacter(i)}>{i}</div>
                                })
                            }
                        </div>
                    </div>

                    <div className="generate-a-story">
                        <p>Generate a story</p>
                        <div className="model">
                            <div className={model === 1 ? 'mode-selected' : ''} onClick={() => this.selectMode(1)}>AI Mode (Recommended)</div>
                            <div className={model === 2 ? 'mode-selected' : ''} onClick={() => this.selectMode(2)}>Free Mode</div>
                        </div>

                        {
                            !ifShowTextArea ?
                                <div className="seize-a-seat">
                                    <div className="explain">
                                        <p>Use AI to generate a story (remaining times: {aiNum})</p>
                                        <Button onClick={() => this.setState({
                                            ifShowTextArea: true
                                        })}>GO! GO! GO!</Button>
                                    </div>
                                </div>
                                : model === 1 ? <div className="ai">
                                    <div className="flex-row align-center space-between">
                                        <div className="iconfont icon-yuyan  flex-row align-center">
                                            <Select
                                                value={aiLanguage}
                                                className="select"
                                                bordered={false}
                                                onChange={(value) => this.setState({ aiLanguage: value })}
                                                options={[
                                                    { value: 'CN', label: 'CN' },
                                                    { value: 'EN', label: 'EN' },
                                                ]}>
                                            </Select>
                                            <Tooltip placement="right" title='Select the language returned by AI'>
                                                <QuestionCircleOutlined style={{ color: 'white', fontSize: '15px', marginLeft: '-10px', zIndex: "5" }} />
                                            </Tooltip>
                                        </div>
                                        {
                                            !ifUsedAI ?
                                                <Button type="primary" onClick={this.generateStory} loading={loading.ai}>generate</Button>
                                                : <Button type="primary" onClick={this.generateStory} loading={loading.ai}>regeneration</Button>
                                        }


                                    </div>

                                    <TextArea autoFocus={true} disabled={loading.ai} className="ai-input" onChange={(e) => this.setState({ story: e.target.value })} value={story} />
                                </div>
                                    : <TextArea autoFocus={true} className="free-input" onChange={(e) => this.setState({ story: e.target.value })} value={story} />
                        }
                    </div>

                    <Button className="start" onClick={this.sumbitStory} loading={loading.submit}>Start</Button>
                </div>
            </div>
        </div >
    }
}

export default withRouter(Hero);
