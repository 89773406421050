import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Web3Store } from "../../stores/web3Store";
import './qzukiModal.less'
import '../../styles/iconfont/font-eth/iconfont.css'
import { EffectCreative } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/swiper.less";
import logger from "../../logger";
import { goToTransaction } from '../../utils/commonUtils'
import opensea from '../../images/qzuki/opensea.png'
import looksrare from '../../images/qzuki/looksrare.png'
import element from '../../images/qzuki/element.png'
import x2y2 from '../../images/qzuki/x2y2.png'
import blur from '../../images/qzuki/blur.png'
import { getRoutePath, MythRouteId } from "../../router-config";
import {
    checkIconDark,
    convertAttributes,
    getBackgroundColor,
    parseTokenId
} from "../../constant/CommonConstant";
import LoadingView from "../loading";

interface IProps {
    web3Store: Web3Store;
    openGalleryModel: boolean
    closeGalleryModel: any
    qzukiData?: any
    qzukiId?: number
}

const QzukiModal = ({ web3Store, closeGalleryModel, openGalleryModel, qzukiData, qzukiId }: IProps) => {
    const [ifIconDark, setIfIconDark] = useState(false)
    const [ifSwiper, setIfSwiper] = useState(false)
    const [owner, setOwner]: any = useState('')
    const [data, setData] = useState({ qzukiData: qzukiData });

    useEffect(() => {
        function executeIfQzukiDetailsReady(data: any) {
            if (checkIconDark(data.backgroundColor)) {
                setIfIconDark(true)
            } else {
                setIfIconDark(false)
            }

            async function getOwner() {
                try {
                    let owner = await web3Store.mythServerApi?.getOwner(data.id);
                    setOwner(owner);
                } catch (e) {
                    logger.error(e);
                }
            }

            getOwner()

            changeWidthSwipershow()
            window.addEventListener('resize', changeWidthSwipershow);
            return () => {
                window.removeEventListener('resize', changeWidthSwipershow);
            }
        }

        if (!data.qzukiData && qzukiId) {
            //personal page
            async function getQzukiDetails(qzukiId: number | undefined) {
                try {
                    if (qzukiId) {
                        const api = web3Store.mythServerApi;
                        const result = await api.getSingleQzuki(qzukiId);

                        if (result) {
                            const qzukiData = {
                                image: result.image,
                                backgroundColor: getBackgroundColor(result),
                                attributesConverted: convertAttributes(result),
                                id: parseTokenId(result.name)
                            };
                            setData({
                                qzukiData: qzukiData
                            });

                            executeIfQzukiDetailsReady(qzukiData);
                        }
                    }
                } catch (e) {
                    logger.error(e);
                }
            }

            getQzukiDetails(qzukiId);
        } else if (data.qzukiData && !qzukiId) {
            //gallery
            data.qzukiData.attributesConverted = convertAttributes(data.qzukiData);
            data.qzukiData.id = parseTokenId(data.qzukiData.name);
            executeIfQzukiDetailsReady(data.qzukiData);
        }

    }, [data, qzukiId, web3Store.mythServerApi]);

    const changeWidthSwipershow = () => {
        if (window.innerWidth <= 670) {
            setIfSwiper(true)
        } else {
            setIfSwiper(false)
        }
    }

    const onCancel = () => {
        closeGalleryModel(false);
    };

    function renderContent() {
        return <>
            <div className="qzukiModal-img"><img src={data.qzukiData.image} alt="" /></div>
            <div className='information-bar'>
                <div className="qzuki-data-header flex-inline-row align-center space-between">
                    <div className="flex-inline-row align-center">
                        <span style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>#</span>
                        <p style={{ color: `${ifIconDark ? 'black' : 'rgba(255, 255, 255, 0.6)'}` }}
                            className='qzuki-text'>QZUKI</p>
                        <p style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>{data.qzukiData.id}</p>
                    </div>
                    <div className="flex-inline-row align-center qzuki-icon">
                        <a href={goToTransaction('opensea', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img
                                src={opensea} alt="" /></a>
                        <a href={goToTransaction('blur', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img src={blur}
                                alt="" /></a>
                        <a href={goToTransaction('element', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img
                                src={element} alt="" /></a>
                        <a href={goToTransaction('x2y2', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img src={x2y2}
                                alt="" /></a>
                        <a href={goToTransaction('looksrare', data.qzukiData.id)} target="_blank"
                            rel="noopener noreferrer"><img
                                src={looksrare} alt="" /></a>
                    </div>
                </div>

                <div className="qzuki-content">
                    {
                        !ifSwiper ?
                            data.qzukiData.attributesConverted && data.qzukiData.attributesConverted.map((item: any, index: number) => {
                                return <div key={index}
                                    className='content flex-row flex-direction-column justify-center'>
                                    <span
                                        style={{ color: `${ifIconDark ? 'black' : 'rgba(255, 255, 255, 0.6)'}` }}>{item.key.toUpperCase()}:</span>
                                    <p style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>{item.value.toUpperCase()}</p>
                                </div>
                            }) :
                            <Swiper
                                modules={[EffectCreative]}
                                grabCursor={true}
                                initialSlide={0}
                                effect={"creative"}
                                creativeEffect={{
                                    prev: {
                                        shadow: true,
                                        translate: [0, 0, -400],
                                        opacity: 0,
                                    },
                                    next: {
                                        shadow: true,
                                        translate: ["100%", 0, 0],
                                        opacity: 1,
                                    },
                                }}
                            >
                                {
                                    data.qzukiData.attributesConverted && data.qzukiData.attributesConverted.map((item: any, index: number) => {
                                        return <SwiperSlide key={index}
                                            className='content flex-row flex-direction-column justify-center swiper-slide'>
                                            <span
                                                style={{ color: `${ifIconDark ? 'black' : 'rgba(255, 255, 255, 0.6)'}` }}>{item.key.toUpperCase()} :</span>
                                            <p style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>{item.value.toUpperCase()}</p>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                    }
                </div>

                {
                    owner ?
                        <div className="collector flex-row align-center" onClick={() => {
                            const url = window.location.origin + getRoutePath(MythRouteId.CollectorPage) + "?wallet=" + owner;
                            window.open(url, '_self');
                        }}>
                            <div className="iconfont icon-renwu-ren"
                                style={{ color: `${ifIconDark ? 'black' : 'white'}` }}></div>
                            <p style={{ color: `${ifIconDark ? 'black' : 'white'}` }}>{owner}</p>
                        </div> :
                        ifSwiper ? '' :
                            <div style={{ padding: '30px 0' }}>

                            </div>
                }
            </div>
        </>;
    }

    function renderLoading() {
        return <LoadingView show={true} ifGolden={true} marginTop={-150} />
    }

    return (
        <Modal open={openGalleryModel} className='qzukiModal' footer={null} onCancel={onCancel} centered
            closable={window.innerWidth <= 930 ? true : false} width={1100}
            bodyStyle={{ backgroundColor: `${data.qzukiData?.backgroundColor}` }}>
            {data.qzukiData && data.qzukiData.id ? renderContent() : renderLoading()}
        </Modal>
    );
};

export default QzukiModal;
