/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./styles/common.css";
import { Layout } from "antd";
import { RootStore } from "./stores/rootStore.js";
import { observer } from "mobx-react";
import { routerList } from "./router-config";

interface IRoutingState {
    isGolden: boolean
}

interface IRoutingProps {
    rootStore: RootStore;
}

@observer
class Routing extends React.Component<IRoutingProps, IRoutingState> {
    constructor(props: any, state: IRoutingState) {
        super(props);
        this.state = {
            isGolden: false
        };
    }

    isIndexGolden = (isGolden: boolean) => {
        this.setState({
            isGolden: isGolden
        })
    }

    render() {
        const { rootStore } = this.props;
        const { Content } = Layout;
        return (
            <Router>
                <Layout className="content-container">
                    <div></div>
                    <Content>
                        <Switch>
                            {routerList.map((route: any, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        render={() => (
                                            <route.component
                                                web3Store={
                                                    rootStore.web3Store
                                                }
                                                rootStore={rootStore}
                                                isIndexGolden={this.isIndexGolden}
                                            />
                                        )}
                                    />
                                );
                            }
                            )}
                        </Switch>
                    </Content>
                </Layout>
            </Router>
        );
    }
}

export default Routing;
